import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, where } from 'firebase/firestore';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ClipLoader from 'react-spinners/ClipLoader';

import { db, auth } from '../Firebase.js';
import { useUser } from '../contexts/UserContext';
import MenuBar from '../MenuBar';
import { signInAnonymously } from 'firebase/auth';

export default function InfiniteLandingPage() {
  const { currentUser, loading } = useUser();
  const [shuffledShows, setShuffledShows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const username = currentUser?.email || currentUser?.uid;

  useEffect(() => {
    const fetchShows = async () => {
      try {
        const showsCollection = collection(db, "landing-page-shows");
        const showsQuery = query(showsCollection, where("front_page", "==", true));
        const querySnapshot = await getDocs(showsQuery);
        const fetchedShows = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const priorityShows = fetchedShows.filter(show => show.priority);
        const nonPriorityShows = fetchedShows.filter(show => !show.priority);
        
        const shuffledPriorityShows = shuffleArray(priorityShows);
        const shuffledNonPriorityShows = shuffleArray(nonPriorityShows);
        
        setShuffledShows([...shuffledPriorityShows, ...shuffledNonPriorityShows]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching shows:", error);
        setIsLoading(false);
      }
    };

    fetchShows();
  }, []);

  const shuffleArray = (array) => {
    const shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    const checkAndSignInAnonymously = async () => {
      if (!currentUser && !loading) {
        try {
          await signInAnonymously(auth);
        } catch (error) {
          console.error('Error during anonymous sign-in:', error);
        }
      }
    };

    checkAndSignInAnonymously();
  }, [currentUser, loading]);

  const trackEvent = async (eventName, eventData) => {
    const data = {
      user: username,
      event_name: eventName,
      manufacturer: 'Web',      
      ...eventData,
    };

    fetch('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleShowClick = (firstEpisodeId) => {
    trackEvent('start-show', { episodeId: firstEpisodeId });
    navigate(`/infinite-show/${firstEpisodeId}`);
  };

  if (loading || isLoading) return (
    <div className="flex justify-center items-center h-screen">
      <ClipLoader color="#36D7B7" size={150} />
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <MenuBar />
      <div className="p-8 pt-16 flex flex-col items-center">
        <div className="w-full flex flex-col justify-center items-center mb-16">
          <div className="text-center">
            <h2 className="text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 to-red-400 leading-none py-1">
              Watch Your AI Adventure Unfold
            </h2>
            <a 
              href="https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-6"
            >
              <img 
                src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" 
                alt="Download on the App Store" 
                className="h-12 transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
              />
            </a>
          </div>
        </div>

        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {shuffledShows.map((show) => (
            <div onClick={() => handleShowClick(show.first_episode_id)} key={show.first_episode_id}>
              <div className="block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-pink-500 hover:shadow-lg transition-shadow duration-300">
                {show.poster ? (
                  <img 
                    src={show.poster} 
                    alt={`${show.title} poster`}
                    className="w-full"
                    style={{ aspectRatio: "9/16" }} 
                  />
                ) : (
                  <div className="w-full" style={{ aspectRatio: "9/16", backgroundColor: "#4a5568" }}></div>
                )}
                <div className="absolute bottom-0 w-full h-full text-white text-xs sm:opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))" }}>
                  <div className="w-full p-2">
                    <div className="font-bold mb-1">{show.title}</div>
                    <div className="text-gray-300 mb-1">@{show.username}</div>
                    <div>{show.description}</div>
                    <div className='text-right mt-2'><span className='rounded text-green-400'>Watch →</span></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full text-center mt-12 mb-8">
          <p className="text-lg text-gray-300">
            Want to make your own show? Follow us for the announcement
          </p>
          <a 
            href="https://x.com/eggnog_ai" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-xl font-bold text-purple-400 hover:text-purple-300 transition-colors duration-200 ease-in-out"
          >
            @eggnog_ai
          </a>
        </div>
      </div>
      <footer className='flex flex-col sm:flex-row justify-center items-center p-4 text-xs'>
        <div className='flex items-center mb-2 sm:mb-0'>
          <span className='text-gray-700 px-2'>© 2024 Eggnog. All rights reserved.</span>
          <a href="https://forms.gle/SK7wRhkpvRjkpyYU9" target="_blank" rel="noopener noreferrer" className='text-gray-700 px-2'>Contact</a>
        </div>
        <div className='flex items-center'>
          <a href="/privacy-policy" className="text-gray-700 hover:text-purple-300 transition-colors duration-200 ease-in-out px-2">Privacy Policy</a>
          <span className='text-gray-700 px-1'>|</span>
          <a href="/eula-policy" className="text-gray-700 hover:text-purple-300 transition-colors duration-200 ease-in-out px-2">EULA</a>
        </div>
      </footer>
    </div>
  );
}