import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MoonLoader from 'react-spinners/MoonLoader';
import 'react-circular-progressbar/dist/styles.css';

import { db, auth } from '../Firebase.js';
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { signInAnonymously } from 'firebase/auth';
import { useUser } from '../contexts/UserContext';
import MenuBar from '../MenuBar';
import '../App.css';
import VideoControls from './VideoControls';
import {Helmet} from "react-helmet";
import { infiniteThumbnail } from '../utils'

const viewCounts = [6.2, 1.8, 4.2, 6.1, 5.2, 9.8, 9.5, 4.5, 1.9, 6.3, 10.2, 1.9, 6,7]

function viewCount(i) {
  // Define the base date and time
  const baseDate = new Date('2024-07-10T01:00:00Z');

  // Get the current date and time
  const now = new Date();

  // Calculate the number of hours that have passed since the base date
  const hoursPassed = Math.max(0, Math.floor((now - baseDate) / (1000 * 60 * 60)));

  // Update viewCounts array
  const formattedViews = (viewCounts[i] + hoursPassed*0.1).toFixed(1) + 'k';
  return formattedViews;
}

const genRandomViewCount = (min) => {
  // Get the current hour of the day (1-24)
  const date = new Date();
  const hoursOfDay = date.getHours() + 1; // Adding 1 to make it 1-24

  // Generate a random number between 1 and 10
  const randomNum = Math.floor(Math.random() * 10) + 1;

  // Calculate the view count
  const viewCount = min + hoursOfDay + randomNum;

  // Return the result as an integer
  return Math.floor(viewCount);
};

function reorderShows(shows) {
  const order = ['Stranger Things', 'Bridgerton', 'Breaking Bad', 'Epstein', 'illuminati'];

  // Create a new array with the specified order
  const orderedShows = order.map(keyword => {
    return shows.find(show => show.title.toLowerCase().includes(keyword.toLowerCase()));
  }).filter(Boolean); // Remove any undefined entries

  // Get the remaining shows
  const remainingShows = shows.filter(show => 
    !order.some(keyword => show.title.toLowerCase().includes(keyword.toLowerCase()))
  );

  // Shuffle the remaining shows
  for (let i = remainingShows.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [remainingShows[i], remainingShows[j]] = [remainingShows[j], remainingShows[i]];
  }

  // Combine the ordered shows with the randomized remaining shows
  return [...orderedShows, ...remainingShows];
}

const NEXT_EPISODE_TIMER = 15;

export default function InfiniteViewAvailable({ shows }) {
    const { currentUser, loading, idToken } = useUser();
    const { specialState, storyId } = useParams();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const fromLogin = query.get('fromLogin');

    const [userData, setUserData] = useState(null);
    const [userNextEpisodeChoice, setUserNextEpisodeChoice] = useState("choice_1");
    const [currentEpisodeId, setCurrentEpisodeId] = useState(storyId || "YgyCdyw2GtVgXtvsS5qB");
    const [videoURL, setVideoURL] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [episodeData, setEpisodeData] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [showPrevEpisodes, setShowPrevEpisodes] = useState(false);

    const [viewCount1, setViewCount1] = useState(null);
    const [viewCount2, setViewCount2] = useState(null);

    const navigate = useNavigate();
    const username = currentUser?.email || currentUser?.uid;
    const videoRef = useRef(null);
    const [progress, setProgress] = useState(0);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [posters, setPosters] = useState({});
    const [descriptions, setDescriptions] = useState({});

    const reorderedShows = reorderShows(shows);

    const [unvisitedEpisodes, setUnvisitedEpisodes] = useState([]);

    // Move trackEvent earlier and memoize it
    const trackEvent = useCallback(async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [username]);

    const loadEpisodeData = useCallback(async (episodeId) => {
        setIsTransitioning(true);
        setIsVideoReady(false);
        setIsLoading(true);
        const episodeDocRef = doc(db, "episodes", episodeId);
        const episodeSnapshot = await getDoc(episodeDocRef);
        const episodeData = episodeSnapshot.data();
        setEpisodeData(episodeData);

        if (episodeData && episodeData.episode_url) {
            setVideoURL(episodeData.episode_url);
        }

        // Fetch child episodes data
        const childEpisodes = {};
        if (episodeData && episodeData.child_episodes) {
            for (const [key, value] of Object.entries(episodeData.child_episodes)) {
                if (value && value.episode_id) {
                    const childEpisodeDocRef = doc(db, "episodes", value.episode_id);
                    const childEpisodeSnapshot = await getDoc(childEpisodeDocRef);
                    childEpisodes[key] = childEpisodeSnapshot.data();
                }
            }
        }
        setEpisodeData(prevData => ({
            ...prevData,
            parent_child_episodes: childEpisodes
        }));

        // Calculate and log the time since the timestamp
        if (episodeData.timestamp) {
            const timestamp = new Date(episodeData.timestamp);
            const now = new Date();
            const timeDiff = Math.abs(now - timestamp);
            const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

            console.log('hoursDiff', hoursDiff);

            if ((episodeData.status === 'PROCESSING' && hoursDiff > 1) || episodeData?.status === 'FAILED') {
                await rescueEpisode(episodeData, idToken);
                setIsLoading(true);
            } else if (episodeData.status === 'PROCESSING' && hoursDiff <= 1) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
        }
        setIsTransitioning(false);
    }, [idToken]);

    console.log(episodeData);

    useEffect(() => {
        if (!currentEpisodeId || !idToken) return;
        loadEpisodeData(currentEpisodeId);
    }, [currentEpisodeId, idToken, loadEpisodeData]);

    const goToNewBranch = useCallback(() => {
        if (unvisitedEpisodes.length > 0) {
            const randomIndex = Math.floor(Math.random() * unvisitedEpisodes.length);
            const newEpisodeId = unvisitedEpisodes[randomIndex];

            setShowButtons(false);
            setShowPrevEpisodes(false);

            trackEvent('go-to-new-branch', { newEpisodeId });

            setCurrentEpisodeId(newEpisodeId);
            loadEpisodeData(newEpisodeId);

            // navigate(`/infinite-show-available/${newEpisodeId}`, { replace: true });

            setUnvisitedEpisodes(prevUnvisited => 
                prevUnvisited.filter(id => id !== newEpisodeId)
            );
        }
    }, [unvisitedEpisodes, navigate, loadEpisodeData, trackEvent]);

    const handleShowClick = (showId) => {
        console.log("handling show click")
        trackEvent('start-show', { firstEpisode: showId });
        navigate(`/infinite-show-available/${showId}`);
        window.location.reload(); // Reload the entire page
    };

    useEffect(() => {
        const fetchPostersAndDescriptions = async () => {
            const postersData = {};
            const descriptionsData = {};
            for (const show of shows) {
                const showDocRef = doc(db, "episodes", show.id);
                const showDoc = await getDoc(showDocRef);
                if (showDoc.exists()) {
                    const showData = showDoc.data();
                    postersData[show.id] = showData.poster;
                    descriptionsData[show.id] = showData.poster_description;
                }
            }
            setPosters(postersData);
            setDescriptions(descriptionsData);
        };

        fetchPostersAndDescriptions();
    }, [shows]);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleMuteUnmute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    const handleVideoClick = () => {
        if (isPlaying) {
            console.log("handleVideoClick, playing")
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            console.log("handleVideoClick, notPlaying")
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleSkipToEnd = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = videoRef.current.duration;
            setIsPlaying(false);
            setShowButtons(true);
        }
    };

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIsMobile(); // Check on initial render

        window.addEventListener('resize', checkIsMobile); // Check on resize

        return () => {
            window.removeEventListener('resize', checkIsMobile); // Cleanup event listener
        };
    }, []);

    useEffect(() => {
        setViewCount1(genRandomViewCount(10))
        setViewCount2(genRandomViewCount(10))
    }, []);

    useEffect(() => {
        const checkAndSignInAnonymously = async () => {
            if (!currentUser && !loading) {
                try {
                    await signInAnonymously(auth);
                } catch (error) {
                    console.error('Error during anonymous sign-in:', error);
                }
            }
        };

        checkAndSignInAnonymously();
    }, [currentUser, loading]);

    useEffect(() => {
        // Set scroll position to top when component is mounted
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!username) return;
        const docRef = doc(db, "character-users", username);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                setUserData(data);
            } else {
                console.log("No such document!");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [username]);

    // Poll for episode status updates if it's in PROCESSING state
    useEffect(() => {
        if (episodeData?.status === 'PROCESSING' || episodeData?.status === 'FAILED') {
            const intervalId = setInterval(async () => {
                const episodeSnapshot = await getDoc(doc(db, "episodes", currentEpisodeId));
                const updatedEpisodeData = episodeSnapshot.data();

                if (updatedEpisodeData.status !== 'PROCESSING') {
                    setEpisodeData(updatedEpisodeData);
                    setIsLoading(updatedEpisodeData.status !== 'COMPLETE');
                    if (updatedEpisodeData.status === 'COMPLETE') {
                        setIsVideoReady(false); // Reset video ready state when new episode is complete
                    }
                    clearInterval(intervalId);
                }
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [episodeData, currentEpisodeId]);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (!videoElement) return;

        const updateProgress = () => {
            const currentProgress = (videoElement.currentTime / videoElement.duration) * 100;
            setProgress(currentProgress);
        };

        videoElement.addEventListener('timeupdate', updateProgress);

        if (fromLogin) {
            setShowButtons(true);
        }

        return () => {
            videoElement.removeEventListener('timeupdate', updateProgress);
        };
    }, [videoURL, fromLogin]);

    const onNextEpisode = async (choice) => {
        setIsTransitioning(true);
        setIsVideoReady(false);
        // Track the choice event
        trackEvent('next-episode-choice', { choice: choice, episodeId: currentEpisodeId });

        // Find the corresponding child episode using the choice key
        const selectedChoice = episodeData?.child_episodes?.[choice];
        if (!selectedChoice) {
            console.error('Invalid choice:', choice);
            return;
        }

        const episodeChoiceKey = choice.toLowerCase();
        const childEpisodeId = selectedChoice.episode_id;

        if (childEpisodeId) {
            // Fetch the child episode data and update state
            setCurrentEpisodeId(childEpisodeId);
            setShowButtons(false); // Hide buttons when loading new episode
            await loadEpisodeData(childEpisodeId);
            return;
        }

        trackEvent('hit-last-loaded-episode', { episodeId: currentEpisodeId });
        // If user is not anonymous, proceed to create the next episode
        const endpoint = 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com/create_next_episode';

        const expressionScale = 1.0;
        const motionBucketId = 127;
        const actionSpeedMultiple = 1.0;
        const voiceStability = 0.3;
        const promptTemperature = 1.0;

        setIsLoading(true);
        setShowButtons(false); // Hide buttons when loading next episode

        const data = {
            user: username,
            username: episodeData?.username,
            user_next_episode_choice: episodeChoiceKey,
            parent_episode_id: currentEpisodeId,
            previous_episodes_list: episodeData?.previous_episodes_list,
            previous_scripts_file: episodeData?.updated_previous_scripts_file,
            characters: episodeData?.characters,
            background_facts: episodeData?.background_facts,
            parent_child_episodes: episodeData?.child_episodes,
            expression_scale: expressionScale,
            motion_bucket_id: motionBucketId,
            action_speed_multiple: actionSpeedMultiple,
            voice_stability: voiceStability,
            prompt_temperature: promptTemperature,
            event_name: 'infinite-video'
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const responseBody = await response.json();
            console.log(`Successfully sent request. Received data back: ${JSON.stringify(responseBody)}. Fetching status...`);

            // Update currentEpisodeId with the new episode_id
            setCurrentEpisodeId(responseBody.episode_id);

            // Load the new episode data
            await loadEpisodeData(responseBody.episode_id);
        } else {
            // Handle the error
            console.error('Failed to start create next episode');
            setIsLoading(false);
        }
    };

    const loadPreviousEpisode = async (episodeId) => {
        setCurrentEpisodeId(episodeId);
        setShowButtons(false); // Hide buttons when loading previous episode
        setShowPrevEpisodes(false);
        await loadEpisodeData(episodeId);
    };

    async function rescueEpisode(episodeData, idToken) {
        if (!idToken) {
            console.error('No idToken available for rescue operation');
            return;
        }

        console.log('Attempting to rescue episode:', episodeData.episode_id);
        console.log('Using idToken', idToken.substring(0, 10) + '...');  // Only log the first 10 characters for security
        trackEvent('hit-last-loaded-episode', { episodeId: episodeData.episode_id });

        try {
            const requestBody = {
                user: username,
                episode_id: currentEpisodeId,
                user_next_episode_choice_description: episodeData.user_next_episode_choice_description,
                parent_episode_id: episodeData.parent_episode_id,
                previous_episodes_list: episodeData.previous_episodes_list,
                previous_scripts_file: episodeData.previous_scripts_file,
                characters: episodeData.characters,
                background_facts: episodeData.background_facts,
                expression_scale: episodeData.expression_scale,
                motion_bucket_id: episodeData.motion_bucket_id,
                action_speed_multiple: episodeData.action_speed_multiple,
                voice_stability: episodeData.voice_stability,
                prompt_temperature: episodeData.prompt_temperature,
            };

            console.log('Request body:', JSON.stringify(requestBody, null, 2));

            const response = await fetch('https://ai-apps-backend-80af17cb1aaa.herokuapp.com/rescue_episode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.text();
            console.log('Response status:', response.status);
            console.log('Response data:', responseData);

            if (response.ok) {
                console.log('Rescue episode request sent successfully');
            } else {
                console.error('Failed to send rescue episode request. Status:', response.status, 'Error:', responseData);
            }
        } catch (error) {
            console.error('Error sending rescue episode request:', error);
            console.error('Error details:', error.message);
            if (error.response) {
                console.error('Response:', await error.response.text());
            }
        }
    }

    const replayVideo = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
        }
        setShowButtons(false);

        // Track the replay event
        trackEvent('replay-episode', { episodeId: currentEpisodeId });
    };

    useEffect(() => {
        if (episodeData) {
            const updatedUnvisitedEpisodes = [...unvisitedEpisodes];
            Object.entries(episodeData.child_episodes || {}).forEach(([key, value]) => {
                if (value.episode_id && !updatedUnvisitedEpisodes.includes(value.episode_id)) {
                    updatedUnvisitedEpisodes.push(value.episode_id);
                }
            });
            setUnvisitedEpisodes(updatedUnvisitedEpisodes);
        }
    }, [episodeData]);

    const handleChoice = (choiceKey) => {
        const selectedEpisodeId = episodeData?.child_episodes?.[choiceKey]?.episode_id;
        if (selectedEpisodeId) {
            setUnvisitedEpisodes(prevUnvisited => 
                prevUnvisited.filter(id => id !== selectedEpisodeId)
            );
        }
        onNextEpisode(choiceKey);
    };

    const ChoiceButton = ({ onClick, children, className = '', style = {}, color = 'PURPLE', disabled = false }) => {
        const colors = {
            RED: {
                bg: 'bg-red-500',
                hoverShadow: 'hover:shadow-red-600',
                focusRing: 'focus:ring-red-300',
                bottomBorder: '#b91c1c'
            },
            GREEN: {
                bg: 'bg-green-500',
                hoverShadow: 'hover:shadow-green-600',
                focusRing: 'focus:ring-green-300',
                bottomBorder: '#15803d'
            },
            PURPLE: {
                bg: 'bg-purple-500',
                hoverShadow: 'hover:shadow-purple-600',
                focusRing: 'focus:ring-purple-300',
                bottomBorder: '#4a1d6e'
            },
            GRAY: {
                bg: 'bg-gray-500',
                hoverShadow: 'hover:shadow-gray-600',
                focusRing: 'focus:ring-gray-300',
                bottomBorder: '#374151'
            },
        };

        const selectedColor = disabled ? colors.GRAY : (colors[color] || colors.PURPLE);

        return (
            <button
                onClick={disabled ? null : onClick}
                className={`
                    ${className}
                    justify-center p-4 text-white w-full h-full flex flex-col 
                    ${selectedColor.bg} bg-opacity-95 rounded-lg
                    shadow-lg transform transition-all duration-150 ease-in-out
                    ${disabled ? '' : `${selectedColor.hoverShadow} hover:shadow-xl hover:-translate-y-1
                    active:translate-y-1 active:shadow-none`}
                    focus:outline-none ${selectedColor.focusRing}
                    relative overflow-hidden
                    ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                style={{
                    boxShadow: `0 8px 0 ${selectedColor.bottomBorder}, 0 15px 20px rgba(0, 0, 0, 0.3)`,
                    ...style
                }}
                disabled={disabled}
            >
                <div className="relative text-left">
                    {children}
                </div>
            </button>
        );
    };

    return (
        <div className="flex flex-col bg-black">
            <Helmet>
                <title>{'Eggnog TV'}</title>
                <meta property="og:title" content={'Eggnog TV'} />
                <meta property="og:description" content={'Choose your own adventure'} />
                <meta property="og:type" content="video.other" />

                {/* Basic video embedding */}
                <meta property="og:video" content={videoURL} />
                <meta property="og:video:secure_url" content={videoURL} />
                <meta property="og:video:type" content="video/mp4" />
                <meta property="og:video:width" content="1280" />
                <meta property="og:video:height" content="720" />

                {/* Thumbnail */}
                <meta property="og:image" content={infiniteThumbnail} />

                {/* Additional video metadata */}
                <meta property="og:video:duration" content="120" />
                <meta property="og:video:release_date" content="2023-07-29" />

                {/* Twitter-specific tags */}
                <meta name="twitter:card" content="player" />
                <meta name="twitter:site" content="@yoursitehandle" />
                <meta name="twitter:title" content={'Eggnog TV'} />
                <meta name="twitter:description" content={'Choose your own adventure'} />
                <meta name="twitter:image" content={infiniteThumbnail} />
                <meta name="twitter:player:width" content="1280" />
                <meta name="twitter:player:height" content="720" />
            </Helmet>
            <div className="relative flex-grow flex justify-center items-start overflow-hidden bg-black">
                <div className="relative w-full h-full flex justify-center items-start bg-black overflow-hidden">
                    <video
                        key={videoURL}
                        ref={videoRef}
                        className={`${isVideoReady ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 max-w-full max-h-screen`}
                        onCanPlay={() => {
                            setIsLoading(false);
                            setIsVideoReady(true);
                            setIsTransitioning(false);
                        }}
                        onEnded={() => {
                            setShowButtons(true);
                            setIsPlaying(false);
                            setProgress(0);
                            if (document.fullscreenElement) {
                                document.exitFullscreen();
                            } else if (document.webkitFullscreenElement) { // Safari
                                document.webkitExitFullscreen();
                            } else if (document.msFullscreenElement) { // IE/Edge
                                document.msExitFullscreen();
                            }
                        }}
                        onClick={handleVideoClick}
                        controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
                        disablePictureInPicture
                        poster={episodeData?.episode_start_screen_url || undefined}
                        playsInline
                        webkit-playsinline
                        controls={false}
                        muted={isMuted}
                    >
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    {isVideoReady && (
                        <VideoControls
                            isPlaying={isPlaying}
                            isMuted={isMuted}
                            onPlayPause={handlePlayPause}
                            onMuteUnmute={handleMuteUnmute}
                            onSkipToEnd={handleSkipToEnd}
                        />
                    )}

                    <div className="absolute top-0 left-0 w-full h-1 bg-gray-800">
                        <div
                            className="h-full bg-gray-500"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>

                    {(isTransitioning || (!isVideoReady && episodeData?.status === 'COMPLETE')) && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black z-50">
                            <MoonLoader color="#ffffff" size={50} />
                        </div>
                    )}

                    {/* Episode buttons container */}
                    {isLoading && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-75 z-30 text-center text-white p-4 space-y-4">
                            <p className="text-2xl font-bold">Generating your next episode...</p>
                            <MoonLoader color="#ffffff" size={50} />
                            <p className="text-xs">Your next episode is 15 minutes away!</p>
                            <p className='text-xl font-bold pt-16 text-purple-400'>Watch something while you wait!</p>

                            <div className="flex flex-row space-x-4 px-4 w-full overflow-x-auto text-left">
                                {reorderedShows.map((show, index) => (
                                    <div className='min-w-36' onClick={() => handleShowClick(show.id)} key={show.id}>
                                        <div className="block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-pink-500 hover:shadow-lg transition-shadow duration-300">
                                            {posters[show.id] ? (
                                                <img 
                                                    src={posters[show.id]} 
                                                    alt={`${show.title} poster`}
                                                    className="w-full"
                                                    style={{ aspectRatio: "9/16" }} 
                                                />
                                            ) : (
                                                <div className="w-full" style={{ aspectRatio: "9/16", backgroundColor: "#4a5568" }}></div>
                                            )}
                                            <div className="font-bold text-md absolute bottom-0 w-full h-full text-white text-xs sm:opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end" style={{ background: "linear-gradient(to top, rgba(25, 25, 25, 1), rgba(0, 0, 0, 0))" }}>
                                                <div className="w-full">
                                                    <div className='p-2'>{descriptions[show.id]}</div>
                                                    <div className='text-right pb-2 pr-2'><span className='rounded text-green-400'>Watch →</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full text-white mt-2 h-20">
                                            <p className='text-left text-xs text-gray-500'>{viewCount(index)} views</p>
                                            <h2 className="text-xs font-bold">{show.title}</h2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {(showPrevEpisodes) && (
                <div className="absolute flex flex-col justify-between z-30 bg-black bg-opacity-70 font-bold inset-0">
                    <div className="flex flex-col h-full items-center space-y-2 sm:space-y-8">
                        <h2 className="text-white text-2xl mb-4 text-left w-3/4 pt-2 sm:w-1/2">Previous episodes</h2>
                        {[...(episodeData?.previous_episodes_list || [])].map((episodeId, index) => (
                            <div key={episodeId} className='w-3/4 h-1/8 sm:w-1/2'>
                                <ChoiceButton onClick={() => {loadPreviousEpisode(episodeId); setShowPrevEpisodes(false)}}>
                                    <div className="text-2xl">
                                        {`Episode ${index + 1}`}
                                    </div>
                                </ChoiceButton>
                            </div>
                        ))}

                        <div className='w-3/4 h-1/8 sm:w-1/2 pt-8'>
                            <ChoiceButton 
                                onClick={() => setShowPrevEpisodes(false)}
                                className="bg-red-500 hover:bg-red-600 focus:ring-red-300"
                                style={{
                                    boxShadow: '0 8px 0 #991b1b, 0 15px 20px rgba(0, 0, 0, 0.3)',
                                }}
                            >
                                <div className="text-2xl">
                                    Back
                                </div>
                            </ChoiceButton>
                        </div>
                    </div>
                </div>
                )}
                {(!showPrevEpisodes && showButtons) && (
                    <div className="absolute flex flex-col justify-between  bg-black bg-opacity-70 font-bold inset-0 z-30">
                        <div className="flex flex-col h-full items-center space-y-8">
                            <h2 className="text-white text-xl mb-4 text-left w-3/4 pt-4 sm:w-1/2">What do you want to see next?</h2>
                            <div className='w-3/4 h-1/8 sm:w-1/2'>
                                <ChoiceButton onClick={replayVideo} >
                                    <div className="relative text-left">
                                        <div className="text-xl"> 
                                            Replay
                                        </div>
                                    </div>
                                </ChoiceButton>
                            </div>

                            {['choice_1', 'choice_2'].map((choiceKey, index) => {
                                const choiceData = episodeData?.child_episodes?.[choiceKey];
                                const isDisabled = !choiceData || !choiceData.episode_id;

                                return (
                                    <div key={choiceKey} className='relative w-3/4 h-1/8 sm:w-1/2'>
                                        <ChoiceButton 
                                            onClick={() => handleChoice(choiceKey)} 
                                            disabled={isDisabled}
                                            color={isDisabled ? 'GRAY' : 'PURPLE'}
                                        >
                                            <div className="relative text-left">
                                                <div className="text-xs font-medium">Option {index + 1}</div> 
                                                <div className="text-xl pb-4"> 
                                                    {choiceData?.description || "Not available"}
                                                </div>
                                            </div>
                                        </ChoiceButton>
                                        {!isDisabled && (
                                            <div className='absolute right-0 bottom-0 bg-red-500 text-white rounded-lg font-bold p-1 m-1 text-xs'>
                                                {index === 0 ? viewCount1 : viewCount2} watching now
                                            </div>
                                        )}
                                    </div>
                                );
                            })}

                            {(!episodeData?.child_episodes?.choice_1?.episode_id && !episodeData?.child_episodes?.choice_2?.episode_id && unvisitedEpisodes.length > 0) && (
                                <div className='w-3/4 h-1/8 sm:w-1/2'>
                                    <ChoiceButton onClick={goToNewBranch} color='GREEN'>
                                        <div className="relative text-left">
                                            <div className="text-xl"> 
                                                Go to new branch
                                            </div>
                                        </div>
                                    </ChoiceButton>
                                </div>
                            )}

                            {episodeData?.previous_episodes_list && episodeData.previous_episodes_list.length > 0 && (
                                <div className='w-3/4 h-1/8 sm:w-1/2'>
                                    <ChoiceButton onClick={() => setShowPrevEpisodes(true)}>
                                        <div className="relative text-left">
                                            <div className="text-xl"> 
                                                Back to previous episodes
                                            </div>
                                        </div>
                                    </ChoiceButton>
                                </div>
                            )}
                            <div className='relative w-3/4 h-1/8 sm:w-1/2'>
                                <ChoiceButton onClick={() => navigate('/infinite')} color='GRAY' >
                                    <div className="relative text-left">
                                        <div className="text-xl"> 
                                            All shows
                                        </div>
                                    </div>
                                </ChoiceButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}